// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-engagement-js": () => import("./../../../src/pages/engagement.js" /* webpackChunkName: "component---src-pages-engagement-js" */),
  "component---src-pages-equipe-js": () => import("./../../../src/pages/equipe.js" /* webpackChunkName: "component---src-pages-equipe-js" */),
  "component---src-pages-examens-js": () => import("./../../../src/pages/Examens.js" /* webpackChunkName: "component---src-pages-examens-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-info-js": () => import("./../../../src/pages/info.js" /* webpackChunkName: "component---src-pages-info-js" */),
  "component---src-templates-examen-js": () => import("./../../../src/templates/examen.js" /* webpackChunkName: "component---src-templates-examen-js" */),
  "component---src-templates-sousexamen-js": () => import("./../../../src/templates/sousexamen.js" /* webpackChunkName: "component---src-templates-sousexamen-js" */)
}

